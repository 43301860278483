import { IData } from 'shared/types/dataType'

export const getTranslations = ({ language }: IData) => {
  return {
    fr: {
      'free-location': 'Free en France',
      collaborators: 'collaborateurs',
      stores: 'boutiques',
      freeProxi: 'équipes Free Proxi',
      'eligible-fiber': 'prises Fibre raccordables',
      'mobile-coverage-4G': 'de la population couverte en 4G *',
      'mobile-coverage-5G': 'de la population couverte en 5G **',
      'no-publication': 'Pas de publication',
      'no-new': "Pas d'actualité",
      'show-more': 'Voir plus',
      'contact-form-error': "Une erreur s'est produite, veuillez réessayer plus tard",
      fr: 'Français',
      en: 'Anglais',
      'last-news': 'Actualités',
      'the-group': 'Le Groupe',
      'last-press-releases': 'Communiqués',
      'press-release': 'Communiqués de presse',
      'financial-release': 'Communiqués de presse financiers',
      'key-figures': 'Chiffres-clés',
      'share-price': "Cours de l'action",
      title: 'Recevoir nos communiqués',
      subscribeTitle: 'Inscription aux communiqués\n du Groupe iliad',
      description: 'Pour recevoir nos communiqués',
      email: 'Adresse e-mail',
      emailSubscribe: 'Adresse Mail*',
      lastname: 'Nom',
      firstname: 'Prénom',
      company: 'Société',
      confidentiality: 'politique de confidentialité',
      'confidentiality-end': '',
      subscriptionLegalMentions:
        'iliad recueille les informations que vous indiquez dans ce formulaire afin de pouvoir vous adresser des communiqués de presse du Groupe iliad. Ce traitement est fondé sur l’intérêt légitime d’iliad de réaliser des opérations de communication dans le cadre des finalités précitées. La Direction de la communication d’iliad ainsi que notre prestataire nous aidant à réaliser ces finalités sont destinataires de ces informations qui seront ensuite supprimées conformément aux dispositions légales applicables. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, vous pouvez consulter notre ',
      'empty-mail-error': 'Veuillez renseigner une adresse email',
      'invalid-mail-error': 'Veuillez renseigner une adresse email valide',
      subscribe: "S'abonner",
      subscribeButton: 'Valider',
      required: '* champs obligatoire',
      unsubscribe: 'Se désabonner',
      thankYou: 'Merci',
      seeYouSoon: 'À bientôt',
      'subscription-success': 'Merci de vous être inscrit à nos communiqués.',
      'unsubscription-success': 'Vous allez recevoir très prochainement un mail confirmant votre désabonnement.',
      'subscription-error': "Une erreur s'est produite lors de votre demande d'inscription",
      'unsubscription-error': "Une erreur s'est produite lors de votre demande de désinscription",
      clickhere: 'cliquez-ici',
      unsubscribeTexte: 'Pour ne plus recevoir nos communiqués',
      'more-information': "Plus d'infos",
      management: 'Management',
      'management-team': 'Equipe dirigeante',
      'board-committee': 'Comités du Conseil d’Administration',
      'board-of-directors': "Conseil d'administration",
      'select-tag': 'Catégorie',
      'select-year': 'Année',
      source: 'src',
      volume: 'vol',
      variation: 'var',
      'legal-information': 'Mentions légales',
      'personal-data': 'Données personnelles',
      'all-rights-reserved': 'Tous droits réservés Groupe iliad © 2021',
      cancel: 'Annuler',
      download: 'Télécharger',
      downloadInProgress: 'Téléchargement de',
      size: 'Taille',
      MB: 'Mo',
      add: 'Ajouter',
      eventColumns: ['Date', 'Événement', 'Exporter vers mon agenda'],
      mobileEventColumns: ['Date', 'Événement', 'Agenda'],
      'no-event': "Pas d'événement",
      print: 'Imprimer',
      back: 'Retour',
      mediaHome: 'Accueil Médias',
      backArticles: 'Retour sur tous les articles',
      'net-debt': 'Dettes nettes',
      'debt-schedule': 'Echéancier de la dette',
      notations: 'Notations',
      'translated-link': 'Read in english',
      'useful-information': 'Informations pratiques',
      submit: 'Envoyer',
      'news-items-filter-placeholder': 'Rechercher des actualités par des mots clés',
      'pubs-filter-placeholder': 'Rechercher des publications par des mots clés'
    },
    en: {
      'free-location': 'Free in France',
      collaborators: 'employees',
      stores: 'stores',
      'eligible-fiber': 'eligible Fiber sockets',
      'mobile-coverage-4G': '4G coverage rate',
      'mobile-coverage-5G': '5G coverage rate',
      'no-publication': 'No publication',
      'no-new': 'No new',
      'show-more': 'See more',
      'contact-form-error': "Une erreur s'est produite, veuillez réessayer plus tard",
      fr: 'French',
      en: 'English',
      'last-news': 'News',
      'the-group': 'About us',
      'last-press-releases': 'News releases',
      'press-release': 'Business news',
      'financial-release': 'Financial news',
      'key-figures': 'Key figures',
      'share-price': 'Share price',
      title: 'Receive our press releases',
      subscribeTitle: "Subscribe to iliad Group's\n press releases",
      description: 'Sign up to receive our press releases',
      email: 'e-mail address',
      emailSubscribe: 'Email address*',
      firstname: 'First Name',
      lastname: 'Last Name',
      company: 'Company',
      'empty-mail-error': 'Please enter an email address',
      'invalid-mail-error': 'Please enter a valid email address',
      subscribe: 'Subscribe',
      subscribeButton: 'Register',
      required: '* field required',
      unsubscribe: 'Unsubscribe',
      thankYou: 'Thank You',
      confidentiality: 'privacy policy',
      'confidentiality-end':
        ' to find out more about the management of your personal data and how to exercise your related rights.',
      subscriptionLegalMentions:
        'iliad collects the information you provide in this form in order to be able to send you press releases from the iliad Group. This processing is based on illiad’s legitimate interest in carrying out communication operations within the framework of the aforementioned purposes. The iliad Communications Department and our dedicated service provider are recipients of this information, which is subsequently deleted in accordance with the applicable legal provisions. See our ',
      'subscription-success': 'Thank you for subscribing to our press releases.',
      'unsubscription-success': 'You will shortly receive an email with a link to confirm your unsubscription',
      'subscription-error': 'An error occurred during your subscription request',
      'unsubscription-error': 'An error occurred during your unsubscription request',
      clickhere: 'click here',
      unsubscribeTexte: 'To stop receiving our news releases',
      'more-information': 'Read More',
      management: 'Management',
      'management-team': 'Management',
      'board-committee': 'Board Committees',
      'board-of-directors': 'Board of directors',
      'select-tag': 'Category',
      'select-year': 'Year',
      source: 'src',
      volume: 'vol',
      variation: 'var',
      'legal-information': 'Legal Notices',
      'personal-data': 'Personal data',
      'all-rights-reserved': 'All rights reserved iliad © 2021',
      cancel: 'Cancel',
      download: 'Download',
      downloadInProgress: 'Downloading',
      size: 'Size',
      MB: 'MB',
      add: 'Add',
      eventColumns: ['Date', 'Event', 'Export to my calendar'],
      mobileEventColumns: ['Date', 'Event', 'Agenda'],
      'no-event': 'No event',
      print: 'Print',
      back: 'Back',
      mediaHome: 'Library',
      backArticles: 'Back to articles',
      'net-debt': 'Net debt',
      'debt-schedule': 'Maturity schedule',
      notations: 'Notations',
      'translated-link': 'Lire en français',
      'useful-information': 'Useful information',
      submit: 'Submit',
      'news-items-filter-placeholder': 'Search news by keywords',
      'pubs-filter-placeholder': 'Search publications by keywords'
    }
  }[language]
}
