class ConfigurationsService {
  constructor() {
    this.ensureValues([
      'REACT_APP_STRAPI_URL',
      'REACT_APP_ENV',
      'REACT_APP_DIDOMI_API_KEY',
      'REACT_APP_RECAPTCHA_PUBLIC_KEY',
      'REACT_APP_ILIAD_CMS_PDF',
      'REACT_APP_STRAPI_HOMMAGE_URL'
    ])
  }

  get countriesEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/countries`
  }

  get emailEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/emails`
  }

  get bondIssuesEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/issues`
  }

  get newsLetterEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/newsletter/subscribe`
  }

  get articleEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/news-items`
  }

  get newsItemsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/news-items/deep-find`
  }

  get newsItemsYearsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/news-items/years`
  }

  get pubsYearsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/pubs/years`
  }

  get carrouselEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/carrousel`
  }

  get eventsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/events`
  }

  get whoWeAreEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/who-are-we`
  }

  get libraryFolderEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/library-folders`
  }

  get tagsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/pub-tags`
  }

  get publicationsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/pubs`
  }

  get keyNumbersEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/key-number`
  }

  get visionEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/vision`
  }

  get governanceEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/governance`
  }

  get lastPublicationsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/last-pubs`
  }

  get testmonialsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/testimonial`
  }

  get whyJoinUsEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/get-why-join-us`
  }

  get imageBaseUrl() {
    const env = this.getValue('REACT_APP_ENV')
    return !(env === 'PROD' || env === 'PREVIEW' || env === 'DEV') ? this.getValue('REACT_APP_STRAPI_URL') : ''
  }

  get newsLetterRegistration() {
    return this.getValue('REACT_APP_NEWSLETTER_REGISTER')
  }

  get recaptchaPublicKey(): string {
    return this.getValue('REACT_APP_RECAPTCHA_PUBLIC_KEY')
  }

  get iliadCmsPdf(): string {
    return this.getValue('REACT_APP_ILIAD_CMS_PDF')
  }

  get hommage(): string {
    return `${this.getValue('REACT_APP_STRAPI_HOMMAGE_URL')}/tribute`
  }

  getPublicationEndpoint(type: string): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/pubs/type/${type}`
  }

  getYearsPublicationEndpoint(type: string): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/pubs/type/${type}/years`
  }

  getRecaptchaEndpoint(): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/recaptcha`
  }

  getSearchEndpoint(collection: string): string {
    return `${this.getValue('REACT_APP_STRAPI_URL')}/${collection}/search`
  }

  getImageFullUrl(imageUrl): string {
    return imageUrl
    // const strapiUrl = this.getValue('REACT_APP_STRAPI_URL')
    // return imageUrl?.startsWith('http')
    //   ? imageUrl
    //   : `${strapiUrl}${strapiUrl.endsWith('/') || imageUrl?.startsWith('/') ? '' : '/'}${imageUrl}`
  }

  getDefaultPublicationIconLink(language: string): string {
    return language === 'en' ? '/free-iliad-icon-en.png' : '/free-iliad-icon.jpg'
  }

  env(key: string): string {
    return this.getValue(key, false)
  }

  private getValue(key: string, throwOnMissing = true): string {
    const environmentValue = (window as any)._env_[key] ?? process.env[key]

    if (environmentValue === undefined && throwOnMissing) {
      throw new Error(`config error - missing env.${key}`)
    }

    return environmentValue ?? ''
  }

  private ensureValues(keys: string[]) {
    keys.forEach((k) => this.getValue(k, true))
    return this
  }
}

export const configurationsService = new ConfigurationsService()
